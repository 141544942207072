// https://developer-docs.amazon.com/sp-api/docs/seller-central-urls
// https://developer-docs.amazon.com/sp-api/docs/marketplace-ids
export const SELLER_CENTRAL_ROOT_PATH = {
  na: {
    A2EUQ1WTGCTBG2: 'https://sellercentral.amazon.ca', // Canada
    ATVPDKIKX0DER: 'https://sellercentral.amazon.com', // US
    A1AM78C64UM0Y8: 'https://sellercentral.amazon.com.mx', // Mexico
    A2Q3Y263D00KWC: 'https://sellercentral.amazon.com.br' // Brazil
  },
  eu: {
    A28R8C7NBKEWEA: 'https://sellercentral.amazon.ie', // Ireland
    A1RKKUPIHCS9HS: 'https://sellercentral-europe.amazon.com', // Spain
    A1F83G8C2ARO7P: 'https://sellercentral-europe.amazon.com', // UK,
    A13V1IB3VIYZZH: 'https://sellercentral-europe.amazon.com', // France
    AMEN7PMS3EDWL: 'https://sellercentral.amazon.com.be', // Belgium
    A1805IZSGTT6HS: 'https://sellercentral.amazon.nl', // Netherlands
    A1PA6795UKMFR9: 'https://sellercentral-europe.amazon.com', // Germany
    APJ6JRA9NG5V4: 'https://sellercentral-europe.amazon.com', // Italy
    A2NODRKZP88ZB9: 'https://sellercentral.amazon.se', // Sweden
    AE08WJ6YKNBMC: 'https://sellercentral.amazon.co.za', // South Africa
    A1C3SOZRARQ6R3: 'https://sellercentral.amazon.pl', // Poland
    ARBP9OOSHTCHU: 'https://sellercentral.amazon.eg', // Egypt
    A33AVAJ2PDY3EV: 'https://sellercentral.amazon.com.tr', // Turkey
    A17E79C6D8DWNP: 'https://sellercentral.amazon.sa', // Saudi Arabia
    A2VIGQ35RCS4UG: 'https://sellercentral.amazon.ae', // UAE
    A21TJRUUN4KGV: 'https://sellercentral.amazon.in' // India
  },
  fe: {
    A1VC38T7YXB528: 'https://sellercentral.amazon.co.jp', // Japan
    A39IBJ37TRP1C6: 'https://sellercentral.amazon.com.au', // Australia
    A19VAU5U5O7RUS: 'https://sellercentral.amazon.sg' // Singapore
  }
}
