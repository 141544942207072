export const PRODUCT_PENDING_ACTION = 'PRODUCT_PENDING_ACTION'
export const PRODUCT_COMPLETED_ACTION = 'PRODUCT_COMPLETED_ACTION'
export const UPDATE_PRODUCT_SEARCH_TERM = 'UPDATE_PRODUCT_SEARCH_TERM'
export const COMPLETED_LOAD_PRODUCTS = 'COMPLETED_LOAD_PRODUCTS'
export const PRODUCT_RESET_PAGINATION_DETAILS =
  'PRODUCT_RESET_PAGINATION_DETAILS'
export const PRODUCT_UPDATE_PAGINATION_DETAILS =
  'PRODUCT_UPDATE_PAGINATION_DETAILS'

export const ENABLE_PARENT_VARIANT_EDITABLE_FIELD =
  'ENABLE_PARENT_VARIANT_EDITABLE_FIELD'
export const UPDATE_PARENT_VARIANT_TITLE = 'UPDATE_PARENT_VARIANT_TITLE'

export const ENABLE_PRODUCT_CHILD_EDITABLE_FIELD =
  'ENABLE_PRODUCT_CHILD_EDITABLE_FIELD'
export const UPDATE_PRODUCT_CHILD_TITLE = 'UPDATE_PRODUCT_CHILD_TITLE'

export const RESET_PRODUCTS = 'RESET_PRODUCTS'

export const UPDATE_PRODUCTS_RESYNC_STATUS_ACTION =
  'UPDATE_PRODUCTS_RESYNC_STATUS_ACTION'
export const UPDATE_GET_RESYNC_STATUS_TIMER_ACTION =
  'UPDATE_GET_RESYNC_STATUS_TIMER_ACTION'
